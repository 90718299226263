import React from "react";
import PickMeals from "../Assets/pick-meals-image2.png";
import ChooseMeals from "../Assets/choose-image2.png";
import DeliveryMeals from "../Assets/delivery-image2.png";
import ReactPlayer from "react-player";

const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "Cotiza",
      text: "Eligir el tipo de negocio ayudara a tener mejor seleccion de herramientas para el sistema adecuado",
    },
    {
      image: ChooseMeals,
      title: "Elige tu Plan",
      text: "Cada plan va con diferentes costos y diferentes herramientas",
    },
    {
      image: DeliveryMeals,
      title: "Utiliza",
      text: "Es momento de poner en marcha el negocio!",
    },
  ];
  return (
    <div className="work-section-wrapper" id='work'>
      <div className="work-section-top">
        <p className="primary-subheading">Funcionalidad</p>
        <h1 className="primary-heading">Como Funciona?</h1>
        <p className="primary-text">
          SISDATA tiene como premisa que un negocio tenga las herramientas necesarias para realizar su labor de manera eficiente y automatica, para ello se sigue los siguientes pasos:
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
      <div className="work-section-video" id='video'>
        <ReactPlayer url="https://youtu.be/a6K05sYvDGs?si=WRvdM9jiYjeTULsP" 
        controls
        width={1280}
        height={720}
        />
      </div>  

    </div>
  );
};

export default Work;
