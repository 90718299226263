/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from "../Assets/logo7.png";
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import {Link} from "react-scroll"
import Sistemas from "../Page/Sistemas";
import {Navigate} from "react-router-dom";




const Navbar = () => {


  const [openMenu, setOpenMenu] = useState(false);

  const [goToContact, setGoToContact] = useState(false);

  const [goToPrincipal, setGoToPrincipal] = useState(false);



  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
    },
    {
      text: "About",
      icon: <InfoIcon />,
    },
    {
      text: "Work",
      icon: <CommentRoundedIcon />,
    },
    {
      text: "Testimonials",
      icon: <CommentRoundedIcon />,
    },
    {
      text: "Contact",
      icon: <PhoneRoundedIcon />,
    },
    {
      text: "Cart",
      icon: <ShoppingCartRoundedIcon />,
    },
  ];

  if(goToContact){
    console.log("actual: "+window.location.pathname);
    if(window.location.pathname !=="/sistemas"){
     
      return <Navigate to="/sistemas" />;
      
    }
    
  }

  if(goToPrincipal){
    if(window.location.pathname !=="/"){
      return <Navigate to="/" />;
    }
  }

  return (
    <nav className="nav">
      <div  onClick={() => {setGoToPrincipal(true);}} className="nav-logo-container">
        <img  className="logon" style={{ cursor: 'pointer',width:'50%', height:'50%' }} src={Logo} alt="" />
      </div>
      <div className="navbar-links-container" >
        <Link onClick={() => {setGoToPrincipal(true);}} style={{ cursor: 'pointer' }} to="/" smooth={true} offset={-20} duration={1000}>INICIO</Link>
        <Link onClick={() => {setGoToPrincipal(true);}} style={{ cursor: 'pointer' }} to="about" smooth={true} offset={-20} duration={1000}>SOBRE NOSOTROS</Link>
        <Link onClick={() => {setGoToPrincipal(true);}} style={{ cursor: 'pointer' }} to="work" smooth={true} offset={-20} duration={1000}>COMO FUNCIONA</Link>
        <Link onClick={() => {setGoToPrincipal(true);}} style={{ cursor: 'pointer' }} to="testimonial" smooth={true} offset={-20} duration={1000}>TESTIMONIOS</Link>
        <Link onClick={() => {setGoToPrincipal(true);}} style={{ cursor: 'pointer' }} to="contact" smooth={true} offset={0} duration={1000}>CONTACTO</Link>
        
        
        <button className="primary-button" id="productos" onClick={() => {setGoToContact(true);}}>VER PRODUCTOS</button>
          
        
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      
    </nav>
    
  );
};

export default Navbar;
