import React, {useState} from "react";
import BannerBackground from "../Assets/home-banner-background2.png";
import MenuImage from "../Assets/ImagenesAutoservicios/MenuPrincipalAuto.jpg";
import LoginImage from "../Assets/ImagenesAutoservicios/AccesoUsuario.jpg";
import PuntoImage from "../Assets/ImagenesAutoservicios/PuntoVentaAuto.jpg";
import HistorialImage from "../Assets/ImagenesAutoservicios/ConsultaHistorialVenta.jpg";
import PrestamoEnvasesImage from "../Assets/ImagenesAutoservicios/PrestamoEnvases.jpg";
import FormasPagoImage from "../Assets/ImagenesAutoservicios/FormasPago.jpg";
import MantenimientoImage from "../Assets/ImagenesAutoservicios/ManteProductosFarma.jpg";
import ComprasImage from "../Assets/ImagenesAutoservicios/RegistroComprasAuto.jpg";
import CuentasXCobrarImage from "../Assets/ImagenesAutoservicios/CuentasXCobrarAuto.jpg";
import OrdenesCompraImage from "../Assets/ImagenesAutoservicios/OrdenesCompra.jpg";
import DiseñoTicketImage from "../Assets/ImagenesAutoservicios/DiseñoTicket.jpg";
import RegistroCompraVentaSunatImage from "../Assets/ImagenesAutoservicios/RegistroCompraVentaSunat.jpg";
import RegistroUtilidadesImage from "../Assets/ImagenesAutoservicios/RegistroUtilidades.jpg";
import AboutBackground from "../Assets/about-background2.png";

import Navbar from "../Components/Navbar";
import { FiArrowRight } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { FiXCircle } from "react-icons/fi";

import Footer from "../Components/Footer";

import {Navigate} from "react-router-dom";
function SistemaAutoservicios() {

  const [goToContact, setGoToContact] = useState(false);

  if(goToContact){
    console.log("actual: "+window.location.pathname);
    if(window.location.pathname !=="/formulario"){
     
      return <Navigate to="/formulario" />;
      
    }
    
  }

  return (
    <div>
    <Navbar/>
    {/*
      <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
      </div>
  */}
      <div className="work-section-wrapper" id="comp_auto" style={{ marginTop:'50px'}}>
      <div className="work-section-top">
        <h1 className="primary-heading" style={{ textAlign: 'center', alignItems:'end' }}>SISTEMA MINIMARKET</h1>
        <p className="primary-text">
          Sistema basado en funcionalidad para ventas de tiendas, bodegas, bazares; al por mayor y menor de productos variados. Con models de ticket para nota de venta, boletas y facturas. El sistema permite que el usuario y/o usuarios puedan acceder y realizar apertura de turnos. Las ventas realizadas en cada turno son acumulativas para el registro y comparativas para resolver el tema de la utilidad obtenida asi como los gastos.
        </p>
        <button className="primary-button" id='btnadquirir' onClick={() => {setGoToContact(true);}}><FiDownload /> ADQUIRIR SISTEMA <FiDownload /></button>
      </div>
      <div className="work-section-bottom">
        
      </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            ACESSO A USUARIO
          </h1>
          <p className="primary-text">
            El sistema permite utilizar nombres de usuario y contraseñas para acceder.
          </p>
        </div>
        <div className="home-image-section">
          <img src={LoginImage} alt="" />
        </div>
      </div>
     {/*
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      */}

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={MenuImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end' }} >
          <h1 className="primary-heading">
            MENU PRINCIPAL
          </h1>
          <p className="primary-text">
            El sistema tiene un interfaz amigable y facil de ubicar.
          </p>    
        </div>
      </div>

      <div className="home-banner-container"  id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            PUNTO DE VENTA
          </h1>
          <p className="primary-text">
            Con el punto de venta se puede realizar los pedidos venta/compra del negocio.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={PuntoImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={HistorialImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
            CONSULTA HISTORIAL DE VENTAS
          </h1>
          <p className="primary-text">
            Un historial ordenado para verificar todas los movimientos.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            PRESTAMOS DE ENVASES
          </h1>
          <p className="primary-text">
            Gestion de prestamo de envases para los clientes.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={PrestamoEnvasesImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container"  id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={FormasPagoImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
            FORMAS DE PAGO
          </h1>
          <p className="primary-text">
            El sistema tiene la opcion de elegir la forma de pago.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            MANTENIMIENTO DE PRODUCTOS
          </h1>
          <p className="primary-text">
            Se pueden crear, eliminar y modificar productos con sus diferentes caracteristicas (precio, costo, nombres, codigos,etc)
          </p> 
        </div>
        <div className="home-image-section">
          <img src={MantenimientoImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={ComprasImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
            REGISTRO DE COMPRAS
          </h1>
          <p className="primary-text">
            Se puede registrar todas las compras que se realizen en el turno.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            CUENTAS POR COBRAR
          </h1>
          <p className="primary-text">
            Gestion para cobrar las deudas que se puedan generar para sus clientes.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={CuentasXCobrarImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={OrdenesCompraImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
            ORDENES DE COMPRAS
          </h1>
          <p className="primary-text">
            Se generan las ordenes para realizar las ordenes de compra.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            DISEÑO DE TICKETS
          </h1>
          <p className="primary-text">
            Diseña y personaliza el ticket de nota de venta, boleta o factura que se generara por cada venta.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={DiseñoTicketImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container " id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={RegistroCompraVentaSunatImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
           REGISTRO DE COMPRA Y VENTA CON LA SUNAT
          </h1>
          <p className="primary-text">
            Cuadro comparativo entre las compras y ventas en cuadre con lo emitido a la sunat.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'100px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
           REGISTRO DE UTILIDADES
          </h1>
          <p className="primary-text">
            El sistema le permite al usuario poder verificar las utilidades seccionadas por cada venta y por intervalo de fechas o turnos.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={RegistroUtilidadesImage} alt="" />
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default SistemaAutoservicios