import React, {useState} from "react";
import BannerBackground from "../Assets/home-banner-background2.png";

import LoginImage from "../Assets/ImagenesDistribuciones/IngresoUsuario.jpg";
import ProductosImage from "../Assets/ImagenesDistribuciones/ManteProductos.jpg";
import ComprobantesImage from "../Assets/ImagenesDistribuciones/EmitirComprobantesVenta.jpg";
import ComprasImage from "../Assets/ImagenesDistribuciones/RegistroCompras.jpg";
import GuiaImage from "../Assets/ImagenesDistribuciones/GuiaElectronica.jpg";
import DistribucionImage from "../Assets/ImagenesDistribuciones/DistribucionMercaderia.jpg";
import LiquidacionVendedoresImage from "../Assets/ImagenesDistribuciones/LiquidacionXVendedores.jpg";
import LiquidacionVentaDiaImage from "../Assets/ImagenesDistribuciones/LiquidacionVentaDia.jpg";
import AltasClienteImage from "../Assets/ImagenesDistribuciones/AltasClientes.jpg";
import AltasProveedoresImage from "../Assets/ImagenesDistribuciones/AltasProveedores.jpg";
import ConsolidadoClientesImage from "../Assets/ImagenesDistribuciones/ConsolidadoClientes.jpg";
import ConsolidadoMercaImage from "../Assets/ImagenesDistribuciones/ConsolidadoMerca.jpg";
import ConsultaLiquidacionesImage from "../Assets/ImagenesDistribuciones/ConsultaLiquidaciones.jpg";
import VerComprobantesImage from "../Assets/ImagenesDistribuciones/VerComprobantesVenta.jpg";

import AboutBackground from "../Assets/about-background2.png";

import Navbar from "../Components/Navbar";
import { FiArrowRight } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { FiXCircle } from "react-icons/fi";

import Footer from "../Components/Footer";

import {Navigate} from "react-router-dom";
function SistemaAutoservicios() {

  const [goToContact, setGoToContact] = useState(false);

  if(goToContact){
    console.log("actual: "+window.location.pathname);
    if(window.location.pathname !=="/formulario"){
     
      return <Navigate to="/formulario" />;
      
    }
    
  }

  return (
    <div>
    <Navbar/>
    {/*
      <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
      </div>
  */}
      <div className="work-section-wrapper" id="comp_auto" style={{ marginTop:'50px'}}>
      <div className="work-section-top">
        <h1 className="primary-heading" style={{ textAlign: 'center', alignItems:'end' }}>SISTEMA DISTRIBUIDORES</h1>
        <p className="primary-text">
          Sistema basado en funcionalidad para ventas al por mayor de productos variados. Con modelos de ticket para nota de venta, boletas y facturas. El sistema permite que el usuario y/o usuarios puedan acceder y realizar apertura de turnos. Las ventas realizadas en cada turno son acumulativas para el registro y comparativas para resolver el tema de la utilidad obtenida asi como los gastos.
        </p>
        <button className="primary-button" id='btnadquirir' onClick={() => {setGoToContact(true);}}><FiDownload /> ADQUIRIR SISTEMA <FiDownload /></button>
      </div>
      <div className="work-section-bottom">
        
      </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        
        <div className="home-image-section">
          <img src={LoginImage} alt="" />
        </div>

        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}}>
          <h1 className="primary-heading">
            ACESSO A USUARIO
          </h1>
          <p className="primary-text">
            El sistema permite utilizar nombres de usuario y contraseñas para acceder.
          </p>
        </div>
      </div>
     {/*
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      */}

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        
        
        <div className="home-text-section" >
          <h1 className="primary-heading">
            MANTENIMIENTO DE PRODUCTOS
          </h1>
          <p className="primary-text">
            Se pueden crear, eliminar y modificar productos con sus diferentes caracteristicas (precio, costo, nombres, codigos,etc)
          </p> 
        </div>
        <div className="home-image-section">
          <img src={ProductosImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={ComprasImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
            REGISTRO DE COMPRAS
          </h1>
          <p className="primary-text">
            Se puede registrar todas las compras que se realizen en el turno.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        
        <div className="home-text-section"  >
          <h1 className="primary-heading">
            EMITIR COMPROBANTES DE VENTAS
          </h1>
          <p className="primary-text">
            Se puede realizar las ventas y emitir comprobantes respectivos, sean boletas, facturas o notas de venta.
          </p>    
        </div>
        <div className="home-image-section">
            <img src={ComprobantesImage} alt="" />
        </div>
      </div>



      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={GuiaImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
            GUIA ELECTRONICA
          </h1>
          <p className="primary-text">
            El sistema permite crear una guia electronica para las ventas que requieran ser entregadas por movilidad a otras partes del pais.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            DISTRIBUCION DE MERCADERIA
          </h1>
          <p className="primary-text">
            Gestion de la mercaderia, distribuyendolo a diferentes puntos de la empresa.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={DistribucionImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container"  id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={LiquidacionVendedoresImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
            LIQUIDACION POR VENDEDORES
          </h1>
          <p className="primary-text">
            El sistema puede realizar la liquidacion de mercaderia y filtrarlo por vendedores.
          </p>    
        </div>
      </div>

      

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            LIQUIDACION POR VENTA DEL DIA
          </h1>
          <p className="primary-text">
            El sistema permite la liquidacion de los productos siendo filtrados por las ventas realizadas en el dia.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={LiquidacionVentaDiaImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={AltasClienteImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
            GESTION DE CLIENTES
          </h1>
          <p className="primary-text">
            Se puede gestionar a los clientes, se pueden agregar, modificar y dar de alta.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
            GESTION DE PROVEEDORES
          </h1>
          <p className="primary-text">
            Se puede gestionar a los proveedores, agregarlos, modificarlos y darlos de alta.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={AltasProveedoresImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container " id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={ConsolidadoClientesImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
           CONSOLIDADO DE CLIENTES
          </h1>
          <p className="primary-text">
            El sistema permite generar un reporte de los movimientos realizados, filtrados respecto a clientes, como al vendedor y a la fecha
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'100px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
           CONSOLIDADO DE MERCADERIA
          </h1>
          <p className="primary-text">
            El sistema permite generar un report de los movimientos de mercaderia, filtrado por fecha.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={ConsolidadoMercaImage} alt="" />
        </div>
      </div>

      <div className="home-banner-container " id="comp_auto" style={{ marginBottom:'50px'}}>
        <div className="home-image-section">
            <img src={ConsultaLiquidacionesImage} alt="" />
        </div>
        <div className="home-text-section" style={{ textAlign: 'end', alignItems:'end'}} >
          <h1 className="primary-heading">
           CONSULTA DE LIQUIDACIONES
          </h1>
          <p className="primary-text">
            Se puede visualizar el reporte de liquidaciones de ventas realizadas en cierto rango de fecha a elegir.
          </p>    
        </div>
      </div>

      <div className="home-banner-container" id="comp_auto" style={{ marginBottom:'100px'}}>
        <div className="home-text-section">
          <h1 className="primary-heading">
           VER COMPROBANTES DE VENTAS
          </h1>
          <p className="primary-text">
            Se puede visualizar los comprobantes de las ventas realizadas con detalle de cada venta.
          </p> 
        </div>
        <div className="home-image-section">
          <img src={VerComprobantesImage} alt="" />
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default SistemaAutoservicios