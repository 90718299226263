import React, {useEffect, useState, useRef} from 'react';
import {data} from '../Assets/data';


const Carrusel = () => {

  const listRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const delay = 2500;
    const listNode = listRef.current;
    const imgNode = listNode.querySelectorAll("li > img")[currentIndex];
    
   
    if( window.pageYOffset === 0){
        if(imgNode){
            imgNode.scrollIntoView({
                behavior:"smooth",block: "end", inline: "nearest"
            });
            
            
        }
   
    }else{
     
    }
    
    
    const interval = setInterval(() =>{
        const isLastSlide = currentIndex === data.length - 1;
        if(!isLastSlide){
            setCurrentIndex(curr => curr +1);
        }else{
            setCurrentIndex(0);
        }
    }, delay);
    return () => clearInterval(interval);
    
  },[currentIndex]);
  

const scrollToImage = (direction) => {
    if(direction === 'prev'){
        setCurrentIndex(curr =>{
            const isFirstSlide = currentIndex === 0;
            return isFirstSlide ? 0 : curr -1;
        })
    } else {
        const isLastSlide = currentIndex === data.length - 1;
        if(!isLastSlide){
            setCurrentIndex(curr => curr +1);
        }
    }
}


  return (
    <div className='main-container'>
        <div className='slider-container'>
            <div className='leftArrow' onClick={() => scrollToImage('prev')}>&#10092;</div>
            <div className='rightArrow' onClick={() => scrollToImage('next')}>&#10093;</div>
            <div className='container-images'>
                <ul className='lista' ref={listRef} >
                    {
                        data.map((item) => {
                            return <li key={item.id}>
                                <img src={item.image} />
                            </li>
                        })
                    }
                </ul>
                
            </div>
        </div>
    </div>
  )
}
export default Carrusel;