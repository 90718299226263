
import BannerBackground from "../Assets/home-banner-background2.png";
import bfarmacia from "../Assets/sistemafarmacia.jpg"
import bautoservicio from "../Assets/sistemaautoservicio.jpg"
import brestaurante from "../Assets/sistemarestaurant.jpg"
import bdistribuciones from "../Assets/sistemadistribuciones.jpg"
import bhoteles from "../Assets/sistemahotel.jpg"
import bsucursal from "../Assets/sistemasucursal.jpg"

import Navbar from "../Components/Navbar";
import React, { useState } from "react";
import {Navigate} from "react-router-dom";
import Footer from "../Components/Footer";
function Sistemas() {

  const [goToAutoservicios, setGoToAutoservicios] = useState(false);
  const [goToFarmacia, setGoToFarmacia] = useState(false);

  const [goToRestaurante, setGoToRestaurante] = useState(false);
  const [goToDistribuciones, setGoToDistribuciones] = useState(false);
  const [goToHoteles, setGoToHoteles] = useState(false);
  const [goToSucursal, setGoToSucursal] = useState(false);


  if(goToAutoservicios){
    return <Navigate to="/sautoservicios" />;
  }

  if(goToFarmacia){
    return <Navigate to="/sfarmacia" />;
  }

  if(goToRestaurante){
    
  }

  if(goToDistribuciones){
    return <Navigate to="/sdistribuciones"/>;
  }
  if(goToHoteles){

  }
  if(goToSucursal){
   
  }


  return (
   
    <div className="Sistemas" id="sistemas">
      <Navbar/>
      <div className="home-container" id='systems'>     
        
      {/*<div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
      </div>  
     */}
      <div className="listaBotones">
        <button className="bfarmacia" onClick={() => {setGoToFarmacia(true);}} style={{backgroundImage: 'url('+bfarmacia+')'}}></button>
        <button className="bautoservicio" onClick={() => {setGoToAutoservicios(true);}} style={{backgroundImage: 'url('+bautoservicio+')'}}></button>
        <button className="brestaurante" onClick={() => {setGoToRestaurante(true);}} style={{backgroundImage: 'url('+brestaurante+')'}}></button>
        <button className="bdistribuciones" onClick={() => {setGoToDistribuciones(true);}} style={{backgroundImage: 'url('+bdistribuciones+')'}}></button>
        <button className="bhoteles" onClick={() => {setGoToHoteles(true);}} style={{backgroundImage: 'url('+bhoteles+')'}}></button>
        <button className="bsucursal" onClick={() => {setGoToSucursal(true);}} style={{backgroundImage: 'url('+bsucursal+')'}}></button>
      </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Sistemas;