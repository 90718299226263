import React, {useState} from "react";
import BannerBackground from "../Assets/home-banner-background2.png";
import BannerImage from "../Assets/home-banner-image2.png";
import Navbar from "../Components/Navbar";
import { FiArrowRight } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { FiXCircle } from "react-icons/fi";
import { Close } from "@mui/icons-material";
import Sistemas from "../Page/Sistemas";
import {Navigate} from "react-router-dom";
import { Resend } from 'resend';
import Newsletter from '../emails/newletters';

import emailjs from '@emailjs/browser';


function Formulario() {

    const[modal,setModal] = useState(false);

    const [goToContact, setGoToContact] = useState(false);

    const [goToPrincipal, setGoToPrincipal] = useState(false);
    

     const [sistema, setSistema] = useState('');

     const [showDelayedText, setShowDelayedText] = useState(false);
   
  
    const redirigir = (url) =>{
      window.open(url, "_blank", "noreferrer");
    }

    if(goToContact){
      console.log("actual: "+window.location.pathname);
      if(window.location.pathname !=="/sistemas"){
       
        return <Navigate to="/sistemas" />;
        
      }
      
    }

    if(goToPrincipal){
      if(window.location.pathname !=="/"){
        return <Navigate to="/" />;
      }
    }

  

    const handleClick = () => {
      setTimeout(() => {
          setShowDelayedText(true);
      }, 1000);
  };
    
 

    const sendEmail = (e) =>{
      e.preventDefault();
      try{
        emailjs.sendForm('service_76skhk9','template_eu37b9y',e.target,'cje5W7wRuPqCF-oXd');
        alert("Se envio el correo correctamente");
        handleClick();
        setGoToPrincipal(true);

      }catch(error){
        alert("No se envio el correo");
        handleClick();
        setGoToPrincipal(true);
      }
     
    }
    


  return (
    <div>
      
         <div className="modal">
          <div 
          className="overlay"></div>
          <div className="modal-content">
            <form className="contact_form" onSubmit={sendEmail}>
            <h2>FORMULARIO PARA ADQUIRIR SISDATA</h2>
            <p>
              Rellene los siguientes datos para enviar una solicitud:
            </p>
            <div className="opcionFarmacia">
              <h3>Nombres Completos: </h3>
              <input type="text" id="nombre" name="nombre" required minlength="4" maxlength="50" size="60" />
            </div>
            <div className="opcionRestaurante">
              <h3>Correo: </h3>
              <input type="text" id="correo" name="correo" required minlength="4" maxlength="50" size="60" />
            </div>
            <div className="opcionComercial">
              <h3>Numero de Telefono: </h3>
              <input type="text" id="telefono" name="telefono" required minlength="4" maxlength="12" size="60" />
            </div>
            <div className="opcionSucursal">
              <h3>Sistema a Elegir: </h3>
            </div>
            <div></div>
            <div className="radiobuttons">
              <div className="opfarmacia">
                <input type="radio" value="Sistema Farmacia" name="radio_farmacia" id="radio_farmacia" onClick={(e) => { setSistema(e.target.value) }} style={{marginRight:'10px'}} /> Sistema Farmacia
              </div>
              <div className="opfarmacia">
              <input type="radio" value="Sistema Autoservicio" name="radio_autoservicio" id="radio_autoservicio" onClick={(e) => { setSistema(e.target.value) }} /> Sistema Autoservicios
              </div>
              <input type="text" name="tipo_sistema" id="tipo_sistema" value={sistema} style={{display: 'none'}}></input>
              
            </div>
            <div className="opcionSucursal">
              <h3>Comentario Adicional: </h3>
            </div>
            <div className="comentariotxt">
              <textarea className="message__box" type="text" id="comentario" name="comentario" required minlength="4" maxlength="100" size="85" /> 
            </div>
            <div className="radiobuttons">
            <button className="secondary-button" style={{marginTop:'10px'}}>ENVIAR</button>
            </div>
            
            
            <button className="close-modal" onClick={() => {setGoToContact(true);}} >CERRAR</button>
           
            </form>
          </div>
         
        </div>
    </div>
  )
}

export default Formulario