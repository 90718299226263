import banner1 from "../Assets/bannerSisdata1.jpg"
import banner2 from "../Assets/bannerSisdata2.jpg"
import banner3 from "../Assets/bannerSisdata3.jpg"

export const data = [
        {
          id:1,  
          image: banner1
        },
        {
          id:2,
          image: banner2
        },
        {
          id:3,
          image: banner3
        },
      ];