import React from "react";

const Contact = () => {
  return (
    <div className="contact-page-wrapper" id='contact'>
      <h1 className="primary-heading">Tienes alguna pregunta?</h1>
      <h1 className="primary-heading">Dejanos un mensaje</h1>
      <div className="contact-form-container">
        <input type="text" placeholder="Aqui escribe tu correo" />
        <button className="secondary-button">Enviar</button>
      </div>
    </div>
  );
};

export default Contact;
