import React, {useState} from "react";
import BannerBackground from "../Assets/home-banner-background2.png";
import BannerImage from "../Assets/home-banner-image2.png";
import Navbar from "./Navbar";
import Carrusel from "./Carrusel";
import { FiArrowRight } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { FiXCircle } from "react-icons/fi";
import { Close } from "@mui/icons-material";
import Sistemas from "../Page/Sistemas";


const Home = () => {

  const[modal,setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal)
  }

  const redirigir = (url) =>{
    window.open(url, "_blank", "noreferrer");
  }


  return (
    <div className="home-container" id='home'>     
        
      {/*
      <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
      </div>
      */}
        
      <div className="home-banner-container">
        
        <div className="home-text-section">
          <h1 className="primary-heading">
            Automatiza tu negocios con un sistema de facturacion electronica
          </h1>
          <p className="primary-text">
            Sistema de facturacion electronica(boletas y facturas), lista para las ventas e inventariado
          </p>
          <button  onClick={toggleModal} className="secondary-button">
            Adquiere tu Demo <FiArrowRight />
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
        {modal &&(
        <div className="modal">
          <div 
          onClick={toggleModal}
          className="overlay"></div>
          <div className="modal-content">
            <h2>Versiones DEMO de los sistemas que ofrece SISDATA</h2>
            <p>
              Elija un sistema que desea probar dependiendo de su negocio:
            </p>
            <div className="opcionFarmacia">
              <h3>Sistema Farmacia</h3>
              <button onClick={() => redirigir("https://drive.usercontent.google.com/download?id=1fe3cvSek5SawfM7qah49qohu5ezlcweC&confirm=t&uuid=2ed25c59-95ee-49ff-aaae-b8521013de5d&at=APZUnTWKR4r70hTjh7px1DErjFms%3A1716587778512")}><FiDownload /> DESCARGAR</button>
            </div>
            <div className="opcionRestaurante">
              <h3>Sistema Restaurante</h3>
              <button onClick={() => redirigir("https://drive.usercontent.google.com/download?id=1oXE5rLKVV3QL6uEE574olIpDAooJqMgm&confirm=t&uuid=00a77f13-1b70-44e2-bfd5-22c89db8cbed&at=APZUnTXeAW_PIeGAJInhotW6FX-z%3A1716588079527")}><FiDownload /> DESCARGAR</button>
            </div>
            <div className="opcionComercial">
              <h3>Sistema Comercial</h3>
              <button onClick={() => redirigir("https://drive.usercontent.google.com/download?id=1hmRlXlOSQ8XIeM5G52VAlrVLv5NvIkEz&confirm=t&uuid=3c5c944b-e384-4a21-9f60-573ed3409b4d&at=APZUnTVemCmdN5vn8D-SF0qkDDGZ%3A1716588256035")}><FiDownload /> DESCARGAR</button>
            </div>
            <div className="opcionSucursal">
              <h3>Sistema Empresa Surcursal</h3>
              <button disabled={true} ><FiXCircle /> DESCARGAR</button>
            </div>
            <div className="opcionHotel">
              <h3>Sistema Hotel</h3>
              <button disabled={true} ><FiXCircle /> DESCARGAR</button>
            </div>
            <div className="guia-instalacion">
            <p>
              Guia de Instalacion:<br/> 
              1.Descargar el archivo comprimido.<br/>
              2.Descomprimir la carpeta interna "Instalador" y colocarlo en un lugar del disco duro.<br/>
              3.Ingresar a la carpeta e iniciar solamente el archivo llamado "Instalador.exe".<br/>
              4.Presionar iniciar y dejar que el sistema se instale solo.
            </p>
            </div>
            
            <button className="close-modal" onClick={toggleModal}>CERRAR</button>
           
            
          </div>
        </div>
        )}
        
      </div>
      
    </div>
  );
};

export default Home;
