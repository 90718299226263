import React from 'react'
import Home from "../Components/Home";
import About from "../Components/About";
import Work from "../Components/Work";
import Testimonial from "../Components/Testimonial";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Carrusel from "../Components/Carrusel";

function Principal() {
  return (
    <div> 
      <Navbar/> 
      <Carrusel/> 
      <Home/>
      <About/>
      <Work/>
      <Testimonial/>
      <Contact />
      <Footer/>
    </div>
  )
}

export default Principal