import React from 'react'

import { AiFillStar } from "react-icons/ai";

export default function CardCliente(props) {
  return (
    <div className="testimonial-section-bottom">
        <img className="card--image" src={props.url} alt="card image" />
        <p>
          {props.comment}
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>{props.name}</h2>
      </div>
  )
}
