import React, {useState} from "react";
import AboutBackground from "../Assets/about-background2.png";
import AboutBackgroundImage from "../Assets/about-background-image2.png";
import { BsFillPlayCircleFill } from "react-icons/bs"
import {Link} from "react-scroll"

const About = () => {
  return (
    <div className="about-section-container" id='about'>
      {/*
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
     */}
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Sobre Nosotros</p>
        <h1 className="primary-heading">
          Tener control rapido y seguro en las ventas es muy importante
        </h1>
        <p className="primary-text">
          Sisdata tiene varios años en el rubro de los sistemas de ventas, facturacion electronica, gestion de productos, gestion de proyectos y automatizacion de negocios.
        </p>
        <p className="primary-text">
          Hoy en dia mas del 95% de los negocios automatiza sus operaciones.
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button">Ver Más</button>
         
          <Link style={{ cursor: 'pointer' }} to="video" smooth={true} offset={-20} duration={1000}> <button className="watch-video-button">
            <BsFillPlayCircleFill /> Ver Video
          </button></Link>
         
        </div>
      </div>
    </div>
  );
};

export default About;
