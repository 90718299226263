import React from "react";
import MachisPic from "../Assets/MachisPic.png";
import FruitPalacePic from "../Assets/FruitPalacePic.png"
import FuegoYSaborPic from "../Assets/FuegoYSaborPic.png"
import DGranjaPic from "../Assets/DGranjaPic.png"
import LaCasonaPic from "../Assets/LaCasonaPic.png"
import PicadelliPic from "../Assets/PicadelliPic.png"
import ElSaborPic from "../Assets/ElSaborPic.png"
import CorporacionLinaresPic from "../Assets/GrupoLinaresPic.png"
import HinojosaPic from "../Assets/HinojosaPic.png"
import RosaditoPic from "../Assets/RosaditoPic.png"
import YoplastPic from "../Assets/YoplastPic.png"
import ElGraneroPic from "../Assets/ElGraneroPic.png"
import HappyMarketPic from "../Assets/HappyMarketPic.png"
import MarbeaMarketPic from "../Assets/MarbeaMarketPic.png"
import GlobalOfficePic from "../Assets/GlobalOfficePic.png"
import MasXMenosPic from "../Assets/MasXMenosPic.png"
import OkFarmaxPic from "../Assets/OkFarmaxPic.png"
import LyRPic from "../Assets/LyRPic.png"
import SolucionFarmaPic from "../Assets/SolucionFarmaPic.png"
import FarmaVidaPic from "../Assets/FarmaVidaPic.png"
import SanteFarmaPic from "../Assets/SanteFarmaPic.png"



import { AiFillStar } from "react-icons/ai";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardCliente from "./CardCliente";

const Testimonial = () => {

  const responsive ={
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2000},
      items:5
    },
    desktop: {
      breakpoint: {max:2000, min:800},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 800, min: 464},
      items: 2,
    },
    mobile: {
      breakpoint:{max:464, min:0},
      items:1,
    },
  };

  const cardData = [
    {
      id:1,
      imageurl: MachisPic,
      name: "Polleria Machi's",
      comment: "Excelente servicio"
    },
    {
      id:2,
      imageurl: FruitPalacePic,
      name: "Jugueria Fruit Palace",
      comment: "Muy buen sistema, rapido"
    },
    {
      id:3,
      imageurl: FuegoYSaborPic,
      name: "Polleria Fuego & Sabor",
      comment: "Ayuda mucho con los pedidos"
    },
    {
      id:4,
      imageurl: DGranjaPic,
      name: "Polleria D' Granja",
      comment: "Muy facil de usar"
    },
    {
      id:5,
      imageurl: LaCasonaPic,
      name: "Polleria La Casona",
      comment: "Rapidez al generar comandas"
    },
    {
      id:6,
      imageurl: PicadelliPic,
      name: "Pizzeria Picadelli's",
      comment: "Muy efectiva, me gusta"
    },
    {
      id:7,
      imageurl: ElSaborPic,
      name: "Polleria El Sabor",
      comment: "Integral y muy adecuado"
    },
    {
      id:8,
      imageurl: CorporacionLinaresPic,
      name: "Corporacion Linares",
      comment: "Super eficiente, me encanta"
    },
    {
      id:9,
      imageurl: HinojosaPic,
      name: "Comercial Hinojosa",
      comment: "Buen estilo del sistema"
    },
    {
      id:10,
      imageurl: RosaditoPic,
      name: "Mayorista Rosadito",
      comment: "Genial y util"
    },
    {
      id:11,
      imageurl: YoplastPic,
      name: "Corporacion Yoplast",
      comment: "De facil manejo y soporte 24/7"
    },
    {
      id:12,
      imageurl: ElGraneroPic,
      name: "Distribuidora El Granero",
      comment: "No hay mejor sistema"
    },
    {
      id:13,
      imageurl: HappyMarketPic,
      name: "Comercial Happy Market",
      comment: "Me ayudo mucho con mi negocio"
    },
    {
      id:14,
      imageurl: MarbeaMarketPic,
      name: "Comercial Marbea Market",
      comment: "Super eficaz y rapido"
    },
    {
      id:15,
      imageurl: GlobalOfficePic,
      name: "Global Office",
      comment: "Excelente servicio de soporte tecnico"
    },
    {
      id:16,
      imageurl: MasXMenosPic,
      name: "Minimarket Mas X Menos",
      comment: "Ayudo a ordenar mis ventas"
    },
    {
      id:17,
      imageurl: OkFarmaxPic,
      name: "Farmacia OK-Farmax",
      comment: "Util para las ventas"
    },
    {
      id:18,
      imageurl: LyRPic,
      name: "Farmacia L&R",
      comment: "Excelente sistema para buscar medicamentos"
    },
    {
      id:19,
      imageurl: SolucionFarmaPic,
      name: "Solucion Farma S.A.C.",
      comment: "Se puede elegir medicamentos por sintomas"
    },
    {
      id:20,
      imageurl: FarmaVidaPic,
      name: "FarmaVida",
      comment: "Eficiente ayuda para el inventario"
    },
    {
      id:21,
      imageurl: SanteFarmaPic,
      name: "Santé Pharma",
      comment: "Muy util"
    },

  ];

  const Card = cardData.map(item =>(
    <CardCliente name={item.name} url={item.imageurl} comment={item.comment}/>
  ))


  return (
    <div className="work-section-wrapper" id='testimonial'>
      <div className="work-section-top">
        <p className="primary-subheading">Testimonios</p>
        <h1 className="primary-heading">Que Dicen Nuestros Clientes?</h1>
        <p className="primary-text">
          Agradecemos la preferencia de nuestros clientes ya que resaltan enormemente la facilidad para realizar facturaciones y boletas emitidas a SUNAT de manera virtual.
        </p>
      </div>

      <Carousel responsive={responsive}>
        {Card}
     </Carousel>
    </div>
  );
};

export default Testimonial;
