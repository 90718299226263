import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Principal from "./Page/Principal";
import Sistemas from "./Page/Sistemas";
import SistemaAutoservicios from "./Page/SistemaAutoservicios";
import SistemaFarmacia from "./Page/SistemaFarmacia";
import SistemaDistribuciones from "./Page/SistemaDistribuciones";
import Formulario from "./Page/Formulario";

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Principal/>}></Route>
          <Route path="/sistemas" element={<Sistemas/>}></Route>
          <Route path="/sautoservicios" element={<SistemaAutoservicios/>}></Route>
          <Route path="/sfarmacia" element={<SistemaFarmacia/>}></Route>
          <Route path="/sdistribuciones" element={<SistemaDistribuciones/>}></Route>
          <Route path="/formulario" element={<Formulario/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
